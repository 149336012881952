import logo1 from '../../logos/collomix-logo.png';
import logo2 from '../../logos/gartenland-logo.png';
import logo3 from '../../logos/geda-logo.png';
import logo4 from '../../logos/granit-logo.png';
import logo5 from '../../logos/heller-logo.png';
import logo6 from '../../logos/hikoki-logo.png';
import logo7 from '../../logos/husqvarna-logo.png';
import logo8 from '../../logos/lissmac-logo.png';
import logo9 from '../../logos/mueba-logo.png';
import logo10 from '../../logos/rhodius-logo.png';
import logo11 from '../../logos/sdmo-logo.png';
import logo12 from '../../logos/wackerneuson-logo.png';
import logo13 from '../../logos/webermt-logo.png';

export const logoData = [
    
    {logo: logo1},
    {logo: logo2},
    // {logo: logo3},
    {logo: logo4},
    {logo: logo5},
    {logo: logo6},
    {logo: logo7},
    {logo: logo8},
    {logo: logo9},
    {logo: logo10},
    {logo: logo11},
    {logo: logo12},
    {logo: logo13}

]